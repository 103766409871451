#session .card {
  justify-content: space-between;
}
#session h2 .sub-header {
  display: block;
  font-size: 1.5rem;
  color: var(--ash);
}

#session .btn {
  height: 5rem;
  font-size: 2rem;
  margin-top: 3rem;
}

#session ul>li {
  font-size: 1.6rem;
  background: url(../img/tick.svg) no-repeat left top; 
  padding-left: 30px;
  margin-bottom: 1.5rem;
}
