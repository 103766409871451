#faq .container {
  padding-bottom: 10rem;
}

#faq .cards-compact .card:not(:last-child) {
  margin-bottom: 1rem;
}

#faq .cards-compact .card:hover,
#faq .cards-compact .card:has(details[open]) {
  background: var(--dark);
  border-radius: 5px;
}

summary {
  padding: 1rem 5rem 1rem 1rem;
  list-style: none;
  display: flex;
  justify-content: space-between;  
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;  
}

summary:after {
  content: '\002B';
  position: absolute;
  top: 3px;
  right: 2rem;
  font-size: 2.2rem;
  transform-origin: 52% 58%;
  transition: .35s transform ease;
}

details[open] summary {
  border-bottom: 2px solid var(--gradient-fallback);
  border-bottom: 1px solid transparent;
  border-image: var(--gradient);
  border-image-slice: 1;
}

details[open] summary:after {
  transform: rotate(135deg);
}

details[open] > *:not(summary) {
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .cards.cards-compact {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .cards.cards-compact .card {
    padding: 0;
  }
}
