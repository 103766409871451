#testimonial blockquote {
  position: relative;

  padding: 4rem;
  text-align: center;

	background-color: var(--dark);
  border-radius: 50px 0;
}

#testimonial blockquote::before {
	content: open-quote;
	top: 0;
	left: 0;
}

#testimonial blockquote::after {
	content: close-quote;
	bottom: 0;
	right: 0;
} 


#testimonial blockquote::before,
#testimonial blockquote::after {
  color: var(--light);
  display: block;
  width: 56px;
  height: 68px;
  line-height: 1.618;
  font-size: 3em;
  border-radius: 100%;
  text-align: center;
  position: absolute;
}

.slider {
  margin-top: 2rem;
  width: 100%;
  max-width: 1050px;
  text-align: center;
  overflow: hidden;
}

.slides {
  --padding-x: calc(max(75px, calc((100% - 850px) / 2)));

  width: 100%;
  padding: 0 var(--padding-x);

  box-sizing: border-box;
  box-shadow: inset 8.5rem 0 4rem -5rem var(--darker),
              inset -8.5rem 0 4rem -5rem var(--darker);

  display: flex;
  gap: 15vw;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slides::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slides {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.slide {
  width: 100%;
  scroll-snap-align: center;
  flex-shrink: 0;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: -1;
}

.slider-btns {
  display: flex;
  width: 100%;
  padding: 8rem 0 5rem 0;
  gap: 1rem;
  justify-content: center;
}

.slider-btn {
  color: var(--light);
  height: 6px;
  width: 70px;
  border: none;
  background-color: var(--dark);
  border-radius: 6px;
  cursor: pointer;
}

.slider-btn-active {
  background-color: var(--gradient-fallback);
  background: var(--gradient);
  transition: background 1000ms linear;
}

.slider-person {
  margin-top: 5rem;

  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.slider-person-active {
  display: flex;
  width: 100%;
}

.slider-person-avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;

  background-color: var(--ash);
  border: 2px solid var(--dark);
}

.slider-person-name {
  width: 180px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
}

/* Don't need button navigation */
@supports (scroll-snap-type) {
  .slider>button {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .slides {
    --padding-x: 10%;
  }
  .slider-btns {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .slides {
    --padding-x: 5rem;
  }
  .slider-person {
    margin-top: 4rem;
  }
  .slider-btns {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 479px) {
  .slides {
    --padding-x: 0;
    box-shadow: none;
  }
}
 