#banner {
  --padding-top: calc(4.5rem + 3.5rem);

  padding-top: var(--padding-top);
  margin-bottom: 3.5rem;

  box-shadow: 0 10px 15px #00000025;
}

#banner .container {
  height: calc(min(70vw, 800px));

  flex-direction: row;
  gap: 3rem;
}

#banner .container>* {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;

  transition: all 1s;
}

.dp {
  align-items: center;
  justify-content: end;
}

.dp img {
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
}

.slogan {
  justify-content: center;

  transform: translateX(100%);
}

.slogan p {
  margin-bottom: 4rem;
}

.action-btns {
  display: flex;
  width: 100%;
  gap: 1.5rem;
  padding: 2rem 0;
}

.action-btns .btn {
  width: 100%;
  height: 5.5rem;
  font-size: calc(min(1.9rem, 1.45vw));
}

@media screen and (min-width: 768px) {
  .dp {
    -webkit-mask-image: linear-gradient(to bottom,var(--dark) 90%,transparent 100%);
    mask-image: linear-gradient(to bottom,var(--dark) 90%,transparent 100%);
    filter: blur(7px);
  }
}

@media screen and (max-width: 991px) {
  #banner .action-btns {
    flex-direction: column;
  }
  #banner .action-btns .btn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #banner {
    box-shadow: none;
  }

  #banner .container {
    height: 100%;
    max-height: 100%;
    flex-direction: column;
  }

  #banner .container>* {
    width: 100%;
  }

  #banner .dp img {
    height: 250px;
    border: 5px solid var(--dark);
    border-radius: 500px;
    background-color: var(--gradient-fallback);
    background: var(--gradient);
  }

  #banner .slogan p {
    margin-bottom: 2rem;
  }

  #banner .action-btns {
    flex-direction: row;
  }

  #banner .action-btns .btn {
    font-size: calc(min(2rem, 2.8vw));
  }
}

@media screen and (max-width: 600px) {
  #banner .action-btns {
    flex-direction: column;
  }
  #banner .action-btns .btn {
    font-size: 1.8rem;
  }
}
