.btn-depressed {
  --bg: var(--dark);
}

.btn-primary {
  --bg: var(--gradient-fallback);
  --bg: var(--gradient);
}

.btn {
  font-weight: 500;
  border: none;
  border-radius: 50px;

  cursor: pointer;
  color: var(--light);
  background: var(--bg);
  transition: 0.2s;
}

.btn:active {
  transform: scale(0.95);
}

@media (hover: hover) and (pointer: fine) {
  .btn:active {
    transform: none;
  }
  .btn:hover:not(:active) {
    transform: translate(0.40rem, -0.40rem);
    background: var(--bg);
    box-shadow: -0.40rem 0.40rem var(--light);
  }
}
