:root {
  --darker: #171717;
  --dark: #1e1e1e;
  --ash: #afafaf;
  --light: #f2f2f2;
  --light-transparent: #f2f2f2cc;
  --gradient: linear-gradient(141deg, #c87cff 0%, #745cfc 50%, #745cfc 60%, #2ddae7 100%);
  --gradient-fallback: #7750ddb3;
}

html {
  color: var(--light);
  background-color: var(--darker);
  height: 100%;
  scroll-behavior: smooth;
  font-family: Open Sans, sans-serif;
  font-size: 10px;
  line-height: 1.4;
  overflow-x: hidden;
}

:focus-visible {
  outline: auto;
}

h1 {
  margin: 0 0 4rem;
  font-size: 4.5rem;
  font-weight: 700;
}

h2 {
  margin: 0;
  font-size: 3.2rem;
  font-weight: 500;
}

h3 {
  margin: 0;
  font-size: 2.2rem;
  font-weight: 400;
}

p {
  color: var(--ash);
}

p, details {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

blockquote {
  margin: 0;
  font-size: 2.1rem;
  line-height: 1.67;
}

body {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
}

#app {
  min-height: var(--viewport-height);
}

main {
  flex-direction: column;
  place-items: center;
  gap: 4rem;
  display: flex;
}

main > section:not(:first-child) {
  padding-top: 10rem;
}

::selection {
  text-shadow: none;
  background: #4b4b4b4d;
}

img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

section {
  width: 100%;
}

section, .container {
  flex-direction: column;
  place-items: center;
  display: flex;
}

mark {
  color: var(--light-transparent);
  background: linear-gradient(180deg, transparent 50%, var(--gradient-fallback) 60%, var(--gradient-fallback) 90%, transparent 90%);
}

a[target="_blank"] {
  color: inherit;
  text-decoration-color: var(--gradient-fallback);
  font-style: italic;
  text-decoration-thickness: 2px;
}

.container {
  width: 90%;
  max-width: 1200px;
}

.highlight {
  background-color: var(--gradient-fallback);
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line-break {
  display: block;
}

.show {
  filter: blur() !important;
  transform: translate(0) !important;
}

h1:not(.slogan *), h3:not(.slogan *), .slider {
  filter: blur(5px);
  transition: all 1s;
  transform: translateY(30%);
}

@media screen and (max-width: 991px) {
  html {
    font-size: 9px;
  }

  h1 {
    font-size: calc(min(4.6vw, 4.2rem));
  }

  h2 {
    font-size: 2.9rem;
  }

  h3 {
    font-size: 2rem;
  }

  p, details {
    font-size: 1.7rem;
  }

  blockquote {
    font-size: 1.85rem;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 10px;
  }

  h1 {
    font-size: 4.2rem;
  }

  p, details {
    font-size: 1.6rem;
  }

  main > section:not(:first-child) {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: calc(min(9vw, 3.9rem));
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  blockquote {
    font-size: 1.5rem;
  }

  .container {
    width: 85%;
  }
}

.btn-depressed {
  --bg: var(--dark);
}

.btn-primary {
  --bg: var(--gradient-fallback);
  --bg: var(--gradient);
}

.btn {
  cursor: pointer;
  color: var(--light);
  background: var(--bg);
  border: none;
  border-radius: 50px;
  font-weight: 500;
  transition: all .2s;
}

.btn:active {
  transform: scale(.95);
}

@media (hover: hover) and (pointer: fine) {
  .btn:active {
    transform: none;
  }

  .btn:hover:not(:active) {
    background: var(--bg);
    box-shadow: -.4rem .4rem var(--light);
    transform: translate(.4rem, -.4rem);
  }
}

.cards {
  width: calc(min(1024px, 100%));
  justify-content: center;
  gap: 5rem;
  padding: 5rem 0;
  display: flex;
}

.card {
  --shadow-color: #0a0a0a8c;
  border: 4px solid var(--darker);
  border-radius: 20px;
  flex-direction: column;
  padding: 2.5rem 5rem 5rem;
  transition: transform 1s;
  display: flex;
}

.card-left {
  box-shadow: 13px -13px 26px var(--shadow-color), -13px 13px 26px var(--shadow-color);
  transform: translateX(-100%);
}

.card-right {
  box-shadow: -13px -13px 26px var(--shadow-color), 13px 13px 26px var(--shadow-color);
  transform: translateX(100%);
}

@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background-color: var(--gradient-fallback);
    background: linear-gradient(var(--darker), var(--darker)) padding-box, var(--gradient) border-box;
    border: 4px solid #0000;
    transition: all .4s;
  }
}

.card h2 {
  margin: 2rem 0 4.5rem;
}

@media screen and (max-width: 991px) {
  .cards {
    padding: 2rem 0;
  }

  .card {
    padding: 2.5rem;
  }
}

.cards.cards-compact {
  flex-direction: column;
  gap: 0;
}

.cards-compact .card {
  box-shadow: none;
  border-radius: unset;
  border: none;
  padding: 0 1rem;
  transform: translateY(100%);
}

@media screen and (max-width: 767px) {
  .cards {
    width: calc(100% - 10rem);
    flex-direction: column;
  }

  .card {
    padding: 2.5rem 4rem 4rem;
  }

  .card h2 {
    margin-bottom: 3.5rem;
  }
}

@media screen and (max-width: 479px) {
  .cards {
    width: 100%;
  }

  .card {
    padding: 2.5rem;
  }
}

nav {
  width: 100vw;
  z-index: 3;
}

.nav-glass-effect {
  -webkit-backdrop-filter: blur(8.4px);
  backdrop-filter: blur(8.4px);
  -webkit-backdrop-filter: blur(8.4px);
  background: #1e1e1eb3;
  box-shadow: 0 4px 30px #0000001a;
}

.sticky {
  position: fixed;
  top: 0;
}

.nav-layout {
  --paddding-aside: 8vw;
  padding: 1.5rem var(--paddding-aside);
  width: calc(100vw - 2 * var(--paddding-aside));
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
}

.nav-layout > div {
  flex-direction: row;
  gap: 3rem;
  display: flex;
}

.nav-layout .home {
  gap: 1.5rem;
  margin-top: -2px;
  font-size: 1.8rem;
}

.nav-layout a {
  color: var(--light);
  text-decoration: none;
}

.hover-underline-animate {
  display: inline-block;
  position: relative;
}

.hover-underline-animate:after {
  content: "";
  width: 110%;
  height: 2px;
  background-color: var(--light);
  transform-origin: 100% 100%;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: -1px;
  left: -5%;
  transform: scaleX(0);
}

.hover-underline-animate:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

@media screen and (max-width: 767px) {
  nav.sticky {
    position: absolute;
  }

  nav .nav-btns {
    display: none;
  }

  nav .nav-layout {
    justify-content: center;
  }
}

#banner {
  --padding-top: calc(4.5rem + 3.5rem);
  padding-top: var(--padding-top);
  margin-bottom: 3.5rem;
  box-shadow: 0 10px 15px #00000025;
}

#banner .container {
  height: calc(min(70vw, 800px));
  flex-direction: row;
  gap: 3rem;
}

#banner .container > * {
  width: 50%;
  height: 100%;
  flex-direction: column;
  transition: all 1s;
  display: flex;
}

.dp {
  justify-content: end;
  align-items: center;
}

.dp img {
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.slogan {
  justify-content: center;
  transform: translateX(100%);
}

.slogan p {
  margin-bottom: 4rem;
}

.action-btns {
  width: 100%;
  gap: 1.5rem;
  padding: 2rem 0;
  display: flex;
}

.action-btns .btn {
  width: 100%;
  height: 5.5rem;
  font-size: calc(min(1.9rem, 1.45vw));
}

@media screen and (min-width: 768px) {
  .dp {
    -webkit-mask-image: linear-gradient(to bottom, var(--dark) 90%, transparent 100%);
    -webkit-mask-image: linear-gradient(to bottom, var(--dark) 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, var(--dark) 90%, transparent 100%);
    filter: blur(7px);
  }
}

@media screen and (max-width: 991px) {
  #banner .action-btns {
    flex-direction: column;
  }

  #banner .action-btns .btn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #banner {
    box-shadow: none;
  }

  #banner .container {
    height: 100%;
    max-height: 100%;
    flex-direction: column;
  }

  #banner .container > * {
    width: 100%;
  }

  #banner .dp img {
    height: 250px;
    border: 5px solid var(--dark);
    background-color: var(--gradient-fallback);
    background: var(--gradient);
    border-radius: 500px;
  }

  #banner .slogan p {
    margin-bottom: 2rem;
  }

  #banner .action-btns {
    flex-direction: row;
  }

  #banner .action-btns .btn {
    font-size: calc(min(2rem, 2.8vw));
  }
}

@media screen and (max-width: 600px) {
  #banner .action-btns {
    flex-direction: column;
  }

  #banner .action-btns .btn {
    font-size: 1.8rem;
  }
}

#testimonial blockquote {
  text-align: center;
  background-color: var(--dark);
  border-radius: 50px 0;
  padding: 4rem;
  position: relative;
}

#testimonial blockquote:before {
  content: open-quote;
  top: 0;
  left: 0;
}

#testimonial blockquote:after {
  content: close-quote;
  bottom: 0;
  right: 0;
}

#testimonial blockquote:before, #testimonial blockquote:after {
  color: var(--light);
  width: 56px;
  height: 68px;
  text-align: center;
  border-radius: 100%;
  font-size: 3em;
  line-height: 1.618;
  display: block;
  position: absolute;
}

.slider {
  width: 100%;
  max-width: 1050px;
  text-align: center;
  margin-top: 2rem;
  overflow: hidden;
}

.slides {
  --padding-x: calc(max(75px, calc((100% - 850px) / 2)));
  width: 100%;
  padding: 0 var(--padding-x);
  box-sizing: border-box;
  box-shadow: inset 8.5rem 0 4rem -5rem var(--darker), inset -8.5rem 0 4rem -5rem var(--darker);
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 15vw;
  display: flex;
  overflow-x: scroll;
}

.slides::-webkit-scrollbar {
  display: none;
}

.slides {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slide {
  width: 100%;
  scroll-snap-align: center;
  transform-origin: center;
  z-index: -1;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: transform .5s;
  display: flex;
  position: relative;
  transform: scale(1);
}

.slider-btns {
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding: 8rem 0 5rem;
  display: flex;
}

.slider-btn {
  color: var(--light);
  height: 6px;
  width: 70px;
  background-color: var(--dark);
  cursor: pointer;
  border: none;
  border-radius: 6px;
}

.slider-btn-active {
  background-color: var(--gradient-fallback);
  background: var(--gradient);
  transition: background 1s linear;
}

.slider-person {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 5rem;
  display: flex;
}

.slider-person-active {
  width: 100%;
  display: flex;
}

.slider-person-avatar {
  height: 58px;
  width: 58px;
  background-color: var(--ash);
  border: 2px solid var(--dark);
  border-radius: 58px;
}

.slider-person-name {
  width: 180px;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

@supports (scroll-snap-type) {
  .slider > button {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .slides {
    --padding-x: 10%;
  }

  .slider-btns {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .slides {
    --padding-x: 5rem;
  }

  .slider-person {
    margin-top: 4rem;
  }

  .slider-btns {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 479px) {
  .slides {
    --padding-x: 0;
    box-shadow: none;
  }
}

#session .card {
  justify-content: space-between;
}

#session h2 .sub-header {
  color: var(--ash);
  font-size: 1.5rem;
  display: block;
}

#session .btn {
  height: 5rem;
  margin-top: 3rem;
  font-size: 2rem;
}

#session ul > li {
  background: url("tick.a00c856c.svg") 0 0 no-repeat;
  margin-bottom: 1.5rem;
  padding-left: 30px;
  font-size: 1.6rem;
}

#faq .container {
  padding-bottom: 10rem;
}

#faq .cards-compact .card:not(:last-child) {
  margin-bottom: 1rem;
}

#faq .cards-compact .card:hover, #faq .cards-compact .card:has(details[open]) {
  background: var(--dark);
  border-radius: 5px;
}

summary {
  cursor: pointer;
  justify-content: space-between;
  padding: 1rem 5rem 1rem 1rem;
  list-style: none;
  display: flex;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  content: "+";
  transform-origin: 52% 58%;
  font-size: 2.2rem;
  transition: transform .35s;
  position: absolute;
  top: 3px;
  right: 2rem;
}

details[open] summary {
  border-bottom: 2px solid var(--gradient-fallback);
  border-bottom: 1px solid #0000;
  border-image: var(--gradient);
  border-image-slice: 1;
}

details[open] summary:after {
  transform: rotate(135deg);
}

details[open] > :not(summary) {
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .cards.cards-compact {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .cards.cards-compact .card {
    padding: 0;
  }
}

footer {
  background-color: var(--dark);
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
}

footer .container {
  text-align: center;
}



/*# sourceMappingURL=index.bc0868dd.css.map */
