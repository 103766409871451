footer {
  padding: 3rem 0;

  font-size: 1.6rem;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--dark);
}

footer .container {
    text-align: center;
}
