.cards {
  width: calc(min(1024px, 100%));
  padding: 5rem 0;

  display: flex;
  justify-content: center;
  gap: 5rem;
}

.card {
  --shadow-color: rgba(10, 10, 10, 0.55);

  display: flex;
  flex-direction: column;

  padding: 2.5rem 5rem 5rem 5rem;

  border: 4px solid var(--darker);
  border-radius: 20px;

  transition: transform 1s;
}

.card-left {
  transform: translateX(-100%);
  box-shadow:  13px -13px 26px var(--shadow-color),
               -13px 13px 26px var(--shadow-color);
}

.card-right {
  transform: translateX(100%);
  box-shadow:  -13px -13px 26px var(--shadow-color),
               13px 13px 26px var(--shadow-color);
}

@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background-color: var(--gradient-fallback);
    background: linear-gradient(var(--darker), var(--darker)) padding-box,
                var(--gradient) border-box;
    transition: 0.4s;
    border: 4px solid transparent;
  }
}

.card h2 {
  margin: 2rem 0 4.5rem 0;
}

@media screen and (max-width: 991px) {
  .cards {
    padding: 2rem 0;
  }
  .card {
    padding: 2.5rem;
  }
}

.cards.cards-compact {
  flex-direction: column;
  gap: 0;
}

.cards-compact .card {
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  border-radius: unset;

  transform: translateY(100%);
}

@media screen and (max-width: 767px) {
  .cards {
    width: calc(100% - 10rem);
    flex-direction: column;
  }

  .card {
    padding: 2.5rem 4rem 4rem 4rem;
  }

  .card h2 {
    margin-bottom: 3.5rem;
  }
}

@media screen and (max-width: 479px) {
  .cards {
    width: 100%;
  }
  .card {
    padding: 2.5rem;
  }
}
